<template>
<!--  订单曲线图-->
  <div id="chartDom" :class="(userLevel == 0) ? 'chartDom' : 'chartDom2'" ref="chart"></div>
</template>

<script>
import * as echarts from 'echarts'
import {getCurrentInstance, onMounted} from "vue-demi";
import {reactive, toRefs, watch} from "vue";
export default {
  name: "OrderLine",
  props: {
    workWeekDay:{
      type: Array,
      default () {
        return []
      }
    },
    transformWeekDay: {
      type: Array,
      default() {
        return []
      }
    },
    lineColor: {
      type: Number,
      default() {
        return 1
      }
    },
    userLevel: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      yAxisName: '',
      yData: [],
      xData: [],
      // 阴影颜色 蓝
      areaStyleColorBlue: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0, // 0% 处的颜色
            color: '#15A2FF'
          },
          {
            offset: 1, // 100% 处的颜色
            color: 'rgba(56,151,216,0)'
          }
        ])
      },
      // 线条颜色 蓝
      itemStyleBlue:  {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
          offset: 0,
          color: '#4e84e3'
        }, {
          offset: 1,
          color: '#27cfde'
        }])
      },
      // 阴影颜色 绿
      areaStyleColorGreen: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0, // 0% 处的颜色
            color: 'rgba(27, 188, 150, 1)'
          },
          {
            offset: 1, // 100% 处的颜色
            color: 'rgba(27, 188, 150, 1)'
          }
        ])
      },
      // 线条颜色 绿
      itemStyleGreen:  {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
          offset: 0,
          color: 'rgba(27, 188, 150, 1)'
        }, {
          offset: 1,
          color: 'rgba(27, 188, 150, 1)'
        }])
      },
      // 默认颜色
      defaultAreaStyleColor: 'areaStyleColorBlue',
      defaultItemStyle: 'itemStyleBlue',
    })

    onMounted(() => {
      orderLine()
    })

    watch(
        [() => props.workWeekDay],
        () => {
          // console.log(props.workWeekDay,props.transformWeekDay ,'传值')
          data.xData = props.workWeekDay
          data.yData = props.transformWeekDay
          data.defaultAreaStyleColor = props.lineColor == 1 ? data.areaStyleColorBlue :data.areaStyleColorGreen
          data.defaultItemStyle = props.lineColor == 1 ? data.itemStyleBlue :data.itemStyleGreen
          orderLine()
        },
        {
          // immediate: true,
          deep: true
        }
    )

    const orderLine = () => {
      let chartDom = proxy.$refs.chart
      if (chartDom) {
        let myChart = echarts.init(chartDom)
        myChart.option = {
          tooltip: { // 提示框组件
            trigger: 'axis', // 坐标轴触发
          },
          xAxis: {
            type: 'category',
            // nameLocation: 'middle', // 坐标轴名称显示位置
            data: data.xData,
            axisLine: { // 坐标轴轴线
              show: true,
              lineStyle: {
                color: '#898e91',
              },
            },
            axisTick: { // 坐标轴刻度
              alignWithLabel: true,
            }
          },
          yAxis: {
            // name: data.yAxisName,
            // nameTextStyle: { // 坐标轴名称的文字样式
            //   color: '#BBBBBB'
            // },
            splitLine:{ // 分隔线
              show: true,
              lineStyle: { //
                color: 'rgba(105, 105, 105, 0.4)',
                width: 1,
                type: 'dashed',
              }
            },
            axisLine: { // 坐标轴轴线
              show: true,
              lineStyle: {
                color: '#898e91',
              },
            }
          },
          grid: {
            top: 40,
            left: 70,
          },
          series: [
            {
              type: 'line',
              data: data.yData,
              smooth: true, //曲线
              areaStyle: data.defaultAreaStyleColor,
              symbol: 'none', // 单个数据标记的图形
              symbolSize: 5,
              sampling: 'average',
              itemStyle: {
                // color: 'rgba(34, 231, 229, 1)',
                normal: data.defaultItemStyle,
              },
            }
          ],
        }
        myChart.option && myChart.setOption(myChart.option)
      }
    }

    return {
      ...toRefs(data),
      orderLine
    }
  }
}
</script>

<style lang="less" scoped>
#chartDom {
  // width: 18vw;
  // height: 17vh;
  
  //margin-top: -16px;
  //width: 20vw;
  //height: 20vh;
}
.chartDom {
  width: 18vw;
  height: 17vh;
}
.chartDom2 {
  width: 38vw;
  height: 28vh;
}
</style>
